import { useEffect, useState } from "react"
import { decodeBlurhash } from "./blurhash-lib"

let canvas: HTMLCanvasElement | undefined

export function useBlurhash(blurhash: string | null) {
  const [url, setUrl] = useState(null as string | null)

  useEffect(() => {
    if (!blurhash) {
      setUrl(null)
      return
    }

    let isCancelled = false

    blurhashToBlob(blurhash).then(
      (blob) => {
        if (!isCancelled && blob) {
          setUrl((oldUrl) => {
            if (oldUrl) {
              URL.revokeObjectURL(oldUrl)
            }
            return URL.createObjectURL(blob)
          })
        }
      },
      (err) => console.error(err),
    )

    return function cleanupBlurhash() {
      isCancelled = true
      setUrl((oldUrl) => {
        if (oldUrl) {
          URL.revokeObjectURL(oldUrl)
        }
        return null
      })
    }
  }, [blurhash])

  return url
}

export function blurhashToBlob(blurhash: string) {
  const punch = 1

  // decode hash
  const { pixels, width, height } = decodeBlurhash(blurhash, punch)

  // temporary canvas to create a blob from decoded ImageData
  if (!canvas) canvas = document.createElement("canvas")
  if (canvas.width !== width) canvas.width = width
  if (canvas.height !== height) canvas.height = height
  const context = canvas.getContext("2d")
  const imageData = context!.createImageData(width, height)
  imageData.data.set(pixels)
  context!.putImageData(imageData, 0, 0)

  return new Promise<Blob>((resolve, reject) => {
    if (!canvas) reject(new Error("Canvas disappeared"))
    canvas?.toBlob((blob) => {
      if (blob) resolve(blob)
      else reject(new Error("Failed to convert blurhash to Blob"))
    })
  })
}
