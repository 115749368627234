import { RemixBrowser } from "@remix-run/react"
import { startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"
import { blurhashToBlob } from "./components/blurhash"

const observer = new MutationObserver((mutations) => {
  for (const mut of mutations) {
    if (mut.type !== "childList") continue
    for (const node of mut.addedNodes) handleNode(node)
  }
})
observer.observe(document.body, { childList: true, subtree: true })
handleNode(document.body)

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
function handleNode(node: Node) {
  if (!isElement(node)) return
  const targets = (node.parentElement || node).querySelectorAll(
    "[data-blurhash]",
  )
  for (const target of targets) {
    if (!isElement(target)) continue
    const img = target.querySelector("img") as HTMLImageElement | undefined
    const blurhash = target.dataset["blurhash"]
    if (!blurhash) continue
    addBlurhashBg(blurhash, img, target).catch((e) => console.error(e))
  }
}

function isElement(node: Node): node is HTMLElement {
  return node.nodeType === Node.ELEMENT_NODE
}

async function addBlurhashBg(
  blurhash: string,
  img: HTMLImageElement | undefined,
  picture: HTMLElement,
) {
  if (img?.complete && img.naturalWidth !== 0) return
  const blob = await blurhashToBlob(blurhash)
  if (img?.complete && img.naturalWidth !== 0) return
  const url = URL.createObjectURL(blob)
  picture.style.backgroundImage = `url(${url})`
  picture.style.backgroundSize = "100% 100%"

  img?.addEventListener(
    "load",
    () => {
      URL.revokeObjectURL(url)
      picture.style.background = "transparent"
    },
    { once: true },
  )
}
